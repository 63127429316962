<template>
  <a-modal
    v-model="visible"
    @cancel="handleCancel"
    :maskClosable="false"
    :width="600"
    :title="isEdit ? (!isShow ? '编辑' : '查看详情') : '新建'"
    on-ok="handleOk"
  >
    <template slot="footer">
      <div style="display:flex;justify-content: space-between;">
        <span></span>
        <span>
          <a-button v-if="!isShow" @click="handleCancel2">
            取消
          </a-button>
          <a-button v-else key="back" @click="handleCancel">
            关闭
          </a-button>
          <a-button v-if="!isShow" key="submit" type="primary" @click="handleOk">
            完成
          </a-button>
          <a-button v-else v-has="'production:term:note:edit'" type="primary" @click="editNote">
            编辑
          </a-button>
        </span>
      </div>
    </template>
    <div v-if="!isShow">
      <div style="display:flex;">
        <div style="width:30%">标题</div>
        <div style="width:70%"><a-input v-model="title" :placeholder="`请输入标题`" /></div>
      </div>
      <div style="display:flex;margin-top:12px;">
        <div style="width:30%">内容</div>
        <div style="width:70%"><a-textarea v-model="content" :placeholder="`请输入笔记内容`" :rows="10" /></div>
      </div>
    </div>
    <div v-else>
      <div style="display:flex;">
        <div style="width:30%">标题</div>
        <div style="width:70%" class="show-text">{{ title }}</div>
      </div>
      <div style="display:flex;margin-top:12px;">
        <div style="width:30%">内容</div>
        <div style="width:70%;height:220px;overflow-y: auto;" class="show-text">{{ content }}</div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { getAction, postAction } from '../../../api/manage'
import { USER_INFO } from '@/store/mutation-types'
import Vue from 'vue'
export default {
  props: {
    parent: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      visible: false,
      isEdit: false,
      userInfo: null,
      content: '',
      title: '',
      recordId: null,
      isShow: false,
      copyData: {
        content: '',
        title: ''
      }
    }
  },
  created() {},
  methods: {
    show() {
      this.isShow = true
    },
    editNote() {
      this.isShow = false
    },
    open(record) {
      if (record) {
        this.isEdit = true
        this.content = record.content
        this.title = record.title
        this.recordId = record.id
        this.copyData.title = this.title
        this.copyData.content = this.content
      } else {
        this.isEdit = false
      }
      this.visible = true
      this.userInfo = Vue.ls.get(USER_INFO)
    },
    async handleOk() {
      if (!this.content && !this.title) return this.$message.error('请输入笔记或标题')
      if (!this.isEdit) {
        let params = {
          productionId: this.parent.productionId,
          username: this.userInfo.nickName,
          userId: this.userInfo.id,
          content: this.content,
          title: this.title,
          avatar: this.userInfo.avatar
        }
        const res = await postAction('/term/notes/edit', params)
        if (res.code == 200) {
          this.$message.success('添加成功')
          this.innitForm()
        } else {
          this.$message.error(res.msg)
        }
      } else {
        let params = {
          productionId: this.parent.productionId,
          username: this.userInfo.nickName,
          userId: this.userInfo.id,
          content: this.content,
          title: this.title,
          id: this.recordId,
          avatar: this.userInfo.avatar
        }
        const res = await postAction('/term/notes/edit', params)
        if (res.code == 200) {
          this.$message.success('编辑成功')
          this.innitForm()
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    handleCancel() {
      this.innitForm()
    },
    handleCancel2() {
      this.isShow = true
      this.title = this.copyData.title
      this.content = this.copyData.content
    },
    innitForm() {
      this.content = ''
      this.title = ''
      this.visible = false
      this.recordId = null
      this.isShow = false
      this.isEdit = false
      this.$emit('loadData')
    }
  }
}
</script>

<style scoped lang="less">
.show-text {
  white-space: pre-line;
  word-wrap: break-word;
  overflow-y: auto;
}
</style>
