var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        maskClosable: false,
        width: 600,
        title: _vm.isEdit ? (!_vm.isShow ? "编辑" : "查看详情") : "新建",
        "on-ok": "handleOk",
      },
      on: { cancel: _vm.handleCancel },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
            },
          },
          [
            _c("span"),
            _c(
              "span",
              [
                !_vm.isShow
                  ? _c("a-button", { on: { click: _vm.handleCancel2 } }, [
                      _vm._v("\n          取消\n        "),
                    ])
                  : _c(
                      "a-button",
                      { key: "back", on: { click: _vm.handleCancel } },
                      [_vm._v("\n          关闭\n        ")]
                    ),
                !_vm.isShow
                  ? _c(
                      "a-button",
                      {
                        key: "submit",
                        attrs: { type: "primary" },
                        on: { click: _vm.handleOk },
                      },
                      [_vm._v("\n          完成\n        ")]
                    )
                  : _c(
                      "a-button",
                      {
                        directives: [
                          {
                            name: "has",
                            rawName: "v-has",
                            value: "production:term:note:edit",
                            expression: "'production:term:note:edit'",
                          },
                        ],
                        attrs: { type: "primary" },
                        on: { click: _vm.editNote },
                      },
                      [_vm._v("\n          编辑\n        ")]
                    ),
              ],
              1
            ),
          ]
        ),
      ]),
      !_vm.isShow
        ? _c("div", [
            _c("div", { staticStyle: { display: "flex" } }, [
              _c("div", { staticStyle: { width: "30%" } }, [_vm._v("标题")]),
              _c(
                "div",
                { staticStyle: { width: "70%" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: `请输入标题` },
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticStyle: { display: "flex", "margin-top": "12px" } },
              [
                _c("div", { staticStyle: { width: "30%" } }, [_vm._v("内容")]),
                _c(
                  "div",
                  { staticStyle: { width: "70%" } },
                  [
                    _c("a-textarea", {
                      attrs: { placeholder: `请输入笔记内容`, rows: 10 },
                      model: {
                        value: _vm.content,
                        callback: function ($$v) {
                          _vm.content = $$v
                        },
                        expression: "content",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _c("div", [
            _c("div", { staticStyle: { display: "flex" } }, [
              _c("div", { staticStyle: { width: "30%" } }, [_vm._v("标题")]),
              _c(
                "div",
                { staticClass: "show-text", staticStyle: { width: "70%" } },
                [_vm._v(_vm._s(_vm.title))]
              ),
            ]),
            _c(
              "div",
              { staticStyle: { display: "flex", "margin-top": "12px" } },
              [
                _c("div", { staticStyle: { width: "30%" } }, [_vm._v("内容")]),
                _c(
                  "div",
                  {
                    staticClass: "show-text",
                    staticStyle: {
                      width: "70%",
                      height: "220px",
                      "overflow-y": "auto",
                    },
                  },
                  [_vm._v(_vm._s(_vm.content))]
                ),
              ]
            ),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }